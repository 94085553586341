import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";

import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import i18n from "@/core/plugins/i18n";
import { Log } from "@/sea/utils/Log";
import vueDebounce from "vue-debounce";
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

import "@/core/plugins/prismjs";
import "bootstrap";

Log.info(
  `%cSushi Eden Admin %c(${process.env.VUE_APP_ENV})`,
  "display: inline-block; color: red; font-weight: bold; font-size: 16px;",
  "display: inline-block; color: blue; font-weight: bold; font-size: 16px; text-transform: uppercase;"
);
Log.debug(`api base url = ${process.env.VUE_APP_API_BASE_URL}`);
Log.debug(`browser language = ${navigator.language}`);

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(i18n);
app.use(vueDebounce);

const toastOptions: PluginOptions = {
  position: POSITION.TOP_CENTER,
  showCloseButtonOnHover: true,
  transition: "Vue-Toastification__fade",
};
app.use(Toast, toastOptions);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.mount("#app");
