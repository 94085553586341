export class Log {
  public static debug(message, ...optionalParams): void {
    if (process.env.VUE_APP_ENV !== "production") {
      if (message.includes("%c")) {
        console.log(
          `%c[SEA|DEBUG] ${message}`,
          "display: inline-block; color: sandybrown;",
          ...optionalParams
        );
      } else {
        console.log(
          "%c%s %c%s",
          "display: inline-block; color: sandybrown;",
          "[SEA|DEBUG]",
          "display: inline-block; color: inherit;",
          message
        );
      }
    }
  }

  public static info(message, ...optionalParams): void {
    if (message.includes("%c")) {
      console.log(
        `%c[SEA|INFO] ${message}`,
        "display: inline-block; color: green;",
        ...optionalParams
      );
    } else {
      console.log(
        "%c%s %c%s",
        "display: inline-block; color: green;",
        "[SEA|INFO]",
        "display: inline-block; color: inherit;",
        message
      );
    }
  }
}
