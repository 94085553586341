export const zhCN = {
  sea: {
    buttons: {
      ok: "确定",
      cancel: "取消",
      yes: "是",
      no: "否",
      back: "返回",
    },
    home: {
      title: "首页",
    },
    loader: {
      loading: "正在加载...",
    },
    alerts: {
      reason: "原因: ",
    },
    aside: {
      categories: {
        dashboard: {
          title: "概要",
        },
        order: {
          title: "订单",
          items: {
            orders: "订单列表",
            statistics: "数据统计",
          },
        },
        product: {
          title: "商品",
          items: {
            products: "商品列表",
            productCategories: "商品分类列表",
          },
        },
        management: {
          title: "管理",
          items: {
            administrators: "管理员列表",
            branches: "分店列表",
          },
        },
      },
    },
    userMenu: {
      language: "语言",
      account: "账户",
      signOut: "退出登录",
    },
    widgets: {
      recentOrders: {
        title: "最新订单",
        orders: "总订单数 {count}",
        totalOrders: "总订单数",
        orderDescription: "{name}已下订单。",
        pickUp: "自提",
        delivery: "外卖",
        paymentMethodCash: "现金",
        paymentMethodEftpos: "刷卡",
        paymentMethodOnline: "Payment Express",
      },
      monthlyOrdersSum: {
        title: "月订单金额",
      },
      monthlyOrdersCount: {
        title: "月订单数",
      },
    },
    pages: {
      signIn: {
        signInToAdmin: "登录Sushi Eden Admin",
        email: "邮箱地址",
        password: "密码",
        continue: "登录",
        pleaseWait: "请稍候...",
        emailRequired: "请输入邮箱地址",
        emailNotValid: "输入的邮箱地址无效",
        passwordRequired: "请输入密码",
        passwordNotLongEnough: "密码长度至少4位",
        loginInvalidGrant: "邮箱地址或密码不正确",
        loginError: "登录请求处理失败",
        tryAgain: "再试一次!",
      },
      error404: {
        title: "未找到页面",
        description: "这里好像什么也没有!",
        back: "返回首页",
      },
      error500: {
        title: "系统错误",
        description: "系统出错了!\n请稍后再试",
        back: "返回首页",
      },
      dashboard: {
        title: "概要",
      },
      statistics: {
        title: "数据统计",
      },
      orders: {
        title: "订单列表",
        selectTextBranchAll: "选择分店",
        searchHintCustomer: "搜索客户",
        selectTextTypeAll: "选择类型",
        selectTextTypePickUp: "自提",
        selectTextTypeDelivery: "外送",
        selectTextStatusAll: "选择状态",
        selectTextStatusPending: "待处理",
        selectTextStatusProcessing: "处理中",
        selectTextStatusProcessed: "处理完毕",
        selectTextStatusReady: "备餐完成",
        selectTextStatusShipping: "外送中",
        selectTextStatusCompleted: "完毕",
        selectTextStatusCancelled: "取消",
        buttonActions: "操作",
        buttonView: "查看详情",
        tblHeaderOrderNumber: "订单编号",
        tblHeaderBranch: "分店",
        tblHeaderPrice: "价格",
        tblHeaderName: "客户姓名",
        tblHeaderContact: "联系方式",
        tblHeaderTelephone: "电话",
        tblHeaderEmail: "邮箱",
        tblHeaderType: "类型",
        tblHeaderStatus: "状态",
        tblHeaderPaymentMethod: "支付方式",
        tblHeaderPaymentStatus: "支付状态",
        tblHeaderCheckoutTime: "自提/外送时间",
        tblHeaderDate: "时间",
        tblHeaderActions: "操作",
        badgeTextTypePickUp: "自提",
        badgeTextTypeDelivery: "外送",
        badgeTextStatusPending: "待处理",
        badgeTextStatusProcessing: "处理中",
        badgeTextStatusProcessed: "处理完毕",
        badgeTextStatusReady: "备餐完成",
        badgeTextStatusShipping: "外送中",
        badgeTextStatusCompleted: "完毕",
        badgeTextStatusCancelled: "取消",
      },
      order: {
        title: "订单详情",
      },
      products: {
        title: "商品列表",
        searchHint: "搜索商品名称",
        selectTextBranchAll: "选择分店",
        selectTextCategoryAll: "选择分类",
        selectTextIsDeliveryAll: "可外送/仅限自提",
        selectTextIsDeliveryDelivery: "可外送",
        selectTextIsDeliveryPickupOnly: "仅限自提",
        selectTextIsEnabledAll: "已启用/已禁用",
        selectTextIsEnabledEnabled: "已启用",
        selectTextIsEnabledDisabled: "已禁用",
        buttonAddProduct: "添加商品",
        buttonActions: "操作",
        buttonView: "查看详情",
        tblHeaderName: "商品名称",
        tblHeaderCategory: "分类",
        tblHeaderPrice: "价格",
        tblHeaderBranch: "分店",
        tblHeaderDelivery: "外送",
        tblHeaderEnabled: "启用",
        tblHeaderDate: "时间",
        tblHeaderActions: "操作",
        badgeTextEnabled: "已启用",
        badgeTextDisabled: "已禁用",
        badgeTextDelivery: "可外送",
        badgeTextPickupOnly: "仅限自提",
      },
      product: {
        title: "商品详情",
      },
      productCategories: {
        title: "商品分类列表",
        searchHint: "搜索商品分类名称",
        buttonAddProductCategory: "添加商品分类",
        buttonActions: "操作",
        buttonView: "查看详情",
        tblHeaderName: "商品分类名称",
        tblHeaderDate: "时间",
        tblHeaderActions: "操作",
      },
      productCategory: {
        title: "商品分类详情",
        productCategoryNotFound: "未找到商品分类信息",
      },
      administrators: {
        title: "管理员列表",
        searchHint: "搜索用户名或邮箱",
        selectTextIsEnabledAll: "已启用/已禁用",
        selectTextIsEnabledEnabled: "已启用",
        selectTextIsEnabledDisabled: "已禁用",
        buttonAddAdministrator: "添加管理员",
        buttonActions: "操作",
        buttonView: "查看详情",
        tblHeaderName: "用户名",
        tblHeaderEmail: "邮箱",
        tblHeaderEnabled: "启用",
        tblHeaderDate: "时间",
        tblHeaderActions: "操作",
        badgeTextEnabled: "已启用",
        badgeTextDisabled: "已禁用",
      },
      administrator: {
        title: "管理员详情",
        administratorNotFound: "未找到管理员信息",
      },
      branches: {
        title: "分店列表",
        searchHint: "搜索店名",
        selectTextIsOpenAll: "已打开/已关闭",
        selectTextIsOpenOpen: "已打开",
        selectTextIsOpenClosed: "已关闭",
        selectTextIsEnabledAll: "已启用/已禁用",
        selectTextIsEnabledEnabled: "已启用",
        selectTextIsEnabledDisabled: "已禁用",
        buttonAddBranch: "添加分店",
        buttonActions: "操作",
        buttonView: "查看详情",
        buttonOpen: "打开分店",
        buttonClose: "关闭分店",
        buttonEnable: "启用分店",
        buttonDisable: "禁用分店",
        tblHeaderName: "店名",
        tblHeaderPhone: "电话",
        tblHeaderAddress: "地址",
        tblHeaderOpen: "开店",
        tblHeaderEnabled: "启用",
        tblHeaderDate: "时间",
        tblHeaderActions: "操作",
        badgeTextOpen: "已打开",
        badgeTextClosed: "已关闭",
        badgeTextEnabled: "已启用",
        badgeTextDisabled: "已禁用",
        modalTitleOpenBranch: "打开分店",
        modalBodyOpenBranch: "确定要打开分店 {name} 吗？",
        modalOkTextOpenBranch: "打开",
        modalNoTextOpenBranch: "取消",
        modalTitleCloseBranch: "关闭分店",
        modalBodyCloseBranch: "确定要关闭分店 {name} 吗？",
        modalOkTextCloseBranch: "关闭",
        modalNoTextCloseBranch: "取消",
        modalTitleEnableBranch: "启用分店",
        modalBodyEnableBranch: "确定要启用分店 {name} 吗？",
        modalOkTextEnableBranch: "启用",
        modalNoTextEnableBranch: "取消",
        modalTitleDisableBranch: "禁用分店",
        modalBodyDisableBranch: "确定要禁用分店 {name} 吗？",
        modalOkTextDisableBranch: "禁用",
        modalNoTextDisableBranch: "取消",
        toastTextBranchOpenedSuccess: "分店 {name} 打开成功",
        toastTextBranchOpenedFailure: "分店 {name} 打开失败",
        toastTextBranchClosedSuccess: "分店 {name} 关闭成功",
        toastTextBranchClosedFailure: "分店 {name} 关闭失败",
        toastTextBranchEnabledSuccess: "分店 {name} 启用成功",
        toastTextBranchEnabledFailure: "分店 {name} 启用失败",
        toastTextBranchDisabledSuccess: "分店 {name} 禁用成功",
        toastTextBranchDisabledFailure: "分店 {name} 禁用失败",
      },
      branch: {
        title: "分店详情",
        branchNotFound: "未找到分店信息",
      },
      account: {
        title: "账户",
        secProfileDetails: "账户信息",
        secChangePassword: "密码设置",
        tabOverview: "总览",
        tabSettings: "设置",
        fieldUsername: "用户名",
        fieldUsernameReadonly: "用户名无法修改",
        fieldEmail: "邮箱地址",
        fieldEmailReadonly: "邮箱地址无法修改",
        fieldLanguage: "语言",
        fieldLanguageDescription: "请选择显示语言，包括日期，时间和数字格式。",
        fieldLanguageRequired: "显示语言为必选项",
        fieldPassword: "密码",
        fieldPasswordDescription: "密码必须至少4位字符",
        fieldCurrentPassword: "当前密码",
        fieldCurrentPasswordRequired: "请输入当前密码",
        fieldNewPassword: "新密码",
        fieldNewPasswordRequired: "请输入新密码",
        fieldNewPasswordNotLongEnough: "密码长度至少4位",
        fieldConfirmNewPassword: "确认新密码",
        fieldConfirmNewPasswordRequired: "请输入确认新密码",
        fieldConfirmNewPasswordNotLongEnough: "确认新密码长度至少4位",
        fieldConfirmNewPasswordNotMatch: "两次输入的新密码不一致",
        btnEditProfile: "更新账户信息",
        btnDiscard: "重置",
        btnSaveChanges: "保存修改",
        btnPleaseWait: "请稍候...",
        btnUpdatePassword: "更新密码",
        btnCancel: "取消",
        msgPasswordChanged: "密码修改成功!",
      },
    },
  },
};
