import { createI18n } from "vue-i18n/index";
import { en } from "@/core/plugins/locales/en";
import { zhCN } from "@/core/plugins/locales/zh-CN";

const messages = {
  en: en,
  "zh-CN": zhCN,
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: {
    zh: ["zh-TW", "zh-CN"],
    "zh-SG": ["zh-CN"],
    "zh-Hans": ["zh-CN"],
    "zh-HK": ["zh-TW", "zh-CN"],
    "zh-MO": ["zh-TW", "zh-CN"],
    "zh-Hant": ["zh-TW", "zh-CN"],
    default: ["en"],
  },
  globalInjection: true,
  messages,
});

export default i18n;
