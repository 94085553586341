import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/orders",
        name: "orders",
        component: () => import("@/views/pages/order/Orders.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/orders/:id",
        name: "order",
        component: () => import("@/views/pages/order/Order.vue"),
        props: (route) => {
          const id = Number(route.params.id) || 0;
          return { id };
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/products",
        name: "products",
        component: () => import("@/views/pages/product/Products.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/product/:id",
        name: "product",
        component: () => import("@/views/pages/product/Product.vue"),
        props: (route) => {
          const id = Number(route.params.id) || 0;
          return { id };
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/product-categories",
        name: "productCategories",
        component: () =>
          import("@/views/pages/productCategory/ProductCategories.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/product-categories/:id",
        name: "productCategory",
        component: () =>
          import("@/views/pages/productCategory/ProductCategory.vue"),
        props: (route) => {
          const id = Number(route.params.id) || 0;
          return { id };
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/statistics",
        name: "statistics",
        component: () => import("@/views/pages/statistics/Statistics.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/administrators",
        name: "administrators",
        component: () =>
          import("@/views/pages/administrator/Administrators.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/administrators/:id",
        name: "administrator",
        component: () =>
          import("@/views/pages/administrator/Administrator.vue"),
        props: (route) => {
          const id = Number(route.params.id) || 0;
          return { id };
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/branches",
        name: "branches",
        component: () => import("@/views/pages/branch/Branches.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/branches/:id",
        name: "branch",
        component: () => import("@/views/pages/branch/Branch.vue"),
        props: (route) => {
          const id = Number(route.params.id) || 0;
          return { id };
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
          },
          {
            path: "projects",
            name: "profile-projects",
            component: () =>
              import("@/views/crafted/pages/profile/Projects.vue"),
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () =>
              import("@/views/crafted/pages/profile/Campaigns.vue"),
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () =>
              import("@/views/crafted/pages/profile/Connections.vue"),
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
          },
        ],
      },
      {
        path: "/account",
        redirect: "/account/overview",
        component: () => import("@/views/pages/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/pages/account/Overview.vue"),
            meta: { requiresAuth: true },
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/pages/account/Settings.vue"),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "/apps/customers/getting-started",
        name: "apps-customers-getting-started",
        component: () => import("@/views/apps/customers/GettingStarted.vue"),
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
      },
      {
        path: "/apps/customers/customer-details",
        name: "apps-customers-details",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      },
      {
        path: "/subscriptions/getting-started",
        name: "subscriptions-getting-started",
        component: () =>
          import("@/views/apps/subscriptions/GettingStarted.vue"),
      },
      {
        path: "/subscriptions/subscription-list",
        name: "subscriptions-subscription-list",
        component: () =>
          import("@/views/apps/subscriptions/SubscriptionList.vue"),
      },
      {
        path: "/subscriptions/add-subscription",
        name: "subscriptions-add-subscription",
        component: () =>
          import("@/views/apps/subscriptions/AddSubscription.vue"),
      },
      {
        path: "/subscriptions/view-subscription",
        name: "subscriptions-view-subscription",
        component: () =>
          import("@/views/apps/subscriptions/ViewSubscription.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/views/pages/auth/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/pages/auth/SignIn.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/pages/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/pages/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  const isAuth = store.getters.isUserAuthenticated;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !isAuth) {
    next("/sign-in");
  } else {
    // Scroll page to top on every route change
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    next();
  }
});

export default router;
