export const en = {
  sea: {
    buttons: {
      ok: "Ok",
      cancel: "Cancel",
      yes: "Yes",
      no: "No",
      back: "Go back",
    },
    home: {
      title: "Home",
    },
    loader: {
      loading: "Loading...",
    },
    alerts: {
      reason: "Reason: ",
    },
    aside: {
      categories: {
        dashboard: {
          title: "Dashboard",
        },
        order: {
          title: "Order",
          items: {
            orders: "Orders",
            statistics: "Statistics",
          },
        },
        product: {
          title: "Product",
          items: {
            products: "Products",
            productCategories: "Product Categories",
          },
        },
        management: {
          title: "Management",
          items: {
            administrators: "Administrators",
            branches: "Branches",
          },
        },
      },
    },
    userMenu: {
      language: "Language",
      account: "Account",
      signOut: "Sign Out",
    },
    widgets: {
      recentOrders: {
        title: "Recent Orders",
        orders: "{count} Orders",
        totalOrders: "Total Orders",
        orderDescription: "{name} has placed an order.",
        pickUp: "Pick Up",
        delivery: "Delivery",
        paymentMethodCash: "Cash",
        paymentMethodEftpos: "Efptos",
        paymentMethodOnline: "Payment Express",
      },
      monthlyOrdersSum: {
        title: "Monthly Sales",
      },
      monthlyOrdersCount: {
        title: "Monthly Orders",
      },
    },
    pages: {
      signIn: {
        signInToAdmin: "Sign In to Sushi Eden Admin",
        email: "Email",
        password: "Password",
        continue: "Continue",
        pleaseWait: "Please wait...",
        emailRequired: "Email is a required field",
        emailNotValid: "Email must be a valid email",
        passwordRequired: "Password is a required field",
        passwordNotLongEnough: "Password must be at least 4 characters",
        loginInvalidGrant: "The login detail is incorrect.",
        loginError: "Failed to process login request.",
        tryAgain: "Try again!",
      },
      error404: {
        title: "Page Not Found",
        description: "Seems there's nothing here",
        back: "Return Home",
      },
      error500: {
        title: "System Error",
        description: "Something went wrong!\nPlease try again later.",
        back: "Return Home",
      },
      dashboard: {
        title: "Dashboard",
      },
      statistics: {
        title: "Statistics",
      },
      orders: {
        title: "Orders",
        selectTextBranchAll: "Select Branch",
        searchHintCustomer: "Search customer",
        selectTextTypeAll: "Select Type",
        selectTextTypePickUp: "Pick Up",
        selectTextTypeDelivery: "Delivery",
        selectTextStatusAll: "Select Status",
        selectTextStatusPending: "Pending",
        selectTextStatusProcessing: "Processing",
        selectTextStatusProcessed: "Processed",
        selectTextStatusReady: "Ready",
        selectTextStatusShipping: "Shipping",
        selectTextStatusCompleted: "Completed",
        selectTextStatusCancelled: "Cancelled",
        buttonActions: "Actions",
        buttonView: "View Order",
        tblHeaderOrderNumber: "Order Number",
        tblHeaderBranch: "Branch",
        tblHeaderPrice: "Price",
        tblHeaderName: "Name",
        tblHeaderContact: "Contact",
        tblHeaderTelephone: "Phone",
        tblHeaderEmail: "Email",
        tblHeaderType: "Type",
        tblHeaderStatus: "Status",
        tblHeaderPaymentMethod: "Payment Method",
        tblHeaderPaymentStatus: "Payment Status",
        tblHeaderCheckoutTime: "Pickup/Delivery Time",
        tblHeaderDate: "Date",
        tblHeaderActions: "Actions",
        badgeTextTypePickUp: "Pick Up",
        badgeTextTypeDelivery: "Delivery",
        badgeTextStatusPending: "Pending",
        badgeTextStatusProcessing: "Processing",
        badgeTextStatusProcessed: "Processed",
        badgeTextStatusReady: "Ready",
        badgeTextStatusShipping: "Shipping",
        badgeTextStatusCompleted: "Completed",
        badgeTextStatusCancelled: "Cancelled",
      },
      order: {
        title: "Order Details",
      },
      products: {
        title: "Products",
        searchHint: "Search product name",
        selectTextBranchAll: "Select Branch",
        selectTextCategoryAll: "Select Category",
        selectTextIsDeliveryAll: "Delivery/Pickup Only",
        selectTextIsDeliveryDelivery: "Delivery",
        selectTextIsDeliveryPickupOnly: "Pickup Only",
        selectTextIsEnabledAll: "Enabled/Disabled",
        selectTextIsEnabledEnabled: "Enabled",
        selectTextIsEnabledDisabled: "Disabled",
        buttonAddProduct: "Add Product",
        buttonActions: "Actions",
        buttonView: "View Product",
        tblHeaderName: "Name",
        tblHeaderCategory: "Category",
        tblHeaderPrice: "Price",
        tblHeaderBranch: "Branch",
        tblHeaderDelivery: "Delivery",
        tblHeaderEnabled: "Enabled",
        tblHeaderDate: "Date",
        tblHeaderActions: "Actions",
        badgeTextEnabled: "Enabled",
        badgeTextDisabled: "Disabled",
        badgeTextDelivery: "Delivery",
        badgeTextPickupOnly: "Pickup Only",
      },
      product: {
        title: "Product Details",
      },
      productCategories: {
        title: "Product Categories",
        searchHint: "Search product category name",
        buttonAddProductCategory: "Add Product Category",
        buttonActions: "Actions",
        buttonView: "View Product Category",
        tblHeaderName: "Name",
        tblHeaderDate: "Date",
        tblHeaderActions: "Actions",
      },
      productCategory: {
        title: "Product Category Details",
        productCategoryNotFound: "Cannot find administrator",
      },
      administrators: {
        title: "Administrators",
        searchHint: "Search name or email",
        selectTextIsEnabledAll: "Enabled/Disabled",
        selectTextIsEnabledEnabled: "Enabled",
        selectTextIsEnabledDisabled: "Disabled",
        buttonAddAdministrator: "Add Administrator",
        buttonActions: "Actions",
        buttonView: "View Administrator",
        tblHeaderName: "Name",
        tblHeaderEmail: "Email",
        tblHeaderEnabled: "Enabled",
        tblHeaderDate: "Date",
        tblHeaderActions: "Actions",
        badgeTextEnabled: "Enabled",
        badgeTextDisabled: "Disabled",
      },
      administrator: {
        title: "Administrator Details",
        administratorNotFound: "Cannot find administrator",
      },
      branches: {
        title: "Branches",
        searchHint: "Search branch name",
        selectTextIsOpenAll: "Open/Closed",
        selectTextIsOpenOpen: "Open",
        selectTextIsOpenClosed: "Closed",
        selectTextIsEnabledAll: "Enabled/Disabled",
        selectTextIsEnabledEnabled: "Enabled",
        selectTextIsEnabledDisabled: "Disabled",
        buttonAddBranch: "Add Branch",
        buttonActions: "Actions",
        buttonView: "View Branch",
        buttonOpen: "Open Branch",
        buttonClose: "Close Branch",
        buttonEnable: "Enable Branch",
        buttonDisable: "Disable Branch",
        tblHeaderName: "Name",
        tblHeaderPhone: "Phone",
        tblHeaderAddress: "Address",
        tblHeaderOpen: "Open",
        tblHeaderEnabled: "Enabled",
        tblHeaderDate: "Date",
        tblHeaderActions: "Actions",
        badgeTextOpen: "Open",
        badgeTextClosed: "Closed",
        badgeTextEnabled: "Enabled",
        badgeTextDisabled: "Disabled",
        modalTitleOpenBranch: "Open branch",
        modalBodyOpenBranch: "Are you sure you want to open branch {name}?",
        modalOkTextOpenBranch: "Open",
        modalNoTextOpenBranch: "Cancel",
        modalTitleCloseBranch: "Close branch",
        modalBodyCloseBranch: "Are you sure you want to close branch {name}?",
        modalOkTextCloseBranch: "Close",
        modalNoTextCloseBranch: "Cancel",
        modalTitleEnableBranch: "Enable branch",
        modalBodyEnableBranch: "Are you sure you want to enable branch {name}?",
        modalOkTextEnableBranch: "Enable",
        modalNoTextEnableBranch: "Cancel",
        modalTitleDisableBranch: "Disable branch",
        modalBodyDisableBranch:
          "Are you sure you want to disable branch {name}?",
        modalOkTextDisableBranch: "Disable",
        modalNoTextDisableBranch: "Cancel",
        toastTextBranchOpenedSuccess: "Branch {name} opened successfully",
        toastTextBranchOpenedFailure: "Failed to open Branch {name}",
        toastTextBranchClosedSuccess: "Branch {name} closed successfully",
        toastTextBranchClosedFailure: "Failed to close branch {name}",
        toastTextBranchEnabledSuccess: "Branch {name} enabled successfully",
        toastTextBranchEnabledFailure: "Failed to enable branch {name}",
        toastTextBranchDisabledSuccess: "Branch {name} disabled successfully",
        toastTextBranchDisabledFailure: "Failed to disable branch {name}",
      },
      branch: {
        title: "Branch Details",
        branchNotFound: "Cannot find branch",
      },
      account: {
        title: "Account",
        secProfileDetails: "Profile Details",
        secChangePassword: "Change Password",
        tabOverview: "Overview",
        tabSettings: "Settings",
        fieldUsername: "Username",
        fieldUsernameReadonly: "Username is not changeable",
        fieldEmail: "Email",
        fieldEmailReadonly: "Email is not changeable",
        fieldLanguage: "Language",
        fieldLanguageDescription:
          "Please select a preferred language, including date, time, and number formatting.",
        fieldLanguageRequired: "Language is a required field",
        fieldPassword: "Password",
        fieldPasswordDescription: "Password must be at least 8 characters",
        fieldCurrentPassword: "Current Password",
        fieldCurrentPasswordRequired: "Current Password is a required field",
        fieldNewPassword: "New Password",
        fieldNewPasswordRequired: "New Password is a required field",
        fieldNewPasswordNotLongEnough:
          "New Password must be at least 4 characters",
        fieldConfirmNewPassword: "Confirm New Password",
        fieldConfirmNewPasswordRequired:
          "Password Confirmation is a required field",
        fieldConfirmNewPasswordNotLongEnough:
          "Password Confirmation must be at least 4 characters",
        fieldConfirmNewPasswordNotMatch: "Passwords must match",
        btnEditProfile: "Edit Profile",
        btnDiscard: "Discard",
        btnSaveChanges: "Save Changes",
        btnPleaseWait: "Please wait...",
        btnUpdatePassword: "Update Password",
        btnCancel: "Cancel",
        msgPasswordChanged: "Password is successfully changed!",
      },
    },
  },
};
